<template>
  <div class="auth-content">
    <v-row class="auth-content-box">
      <v-col class="auth-content-box-wrapper" >
        <div class="header-box">
          <v-row >
            <v-col cols="auto"
              class="header"
              @click="toRoute ('/login')"
            >
              {{ $t('label.login') }}
            </v-col>
            <v-col cols="auto"
              class="header inactive"
              @click="toRoute ('/registration')"
            >
              {{ $t('label.registration') }}
            </v-col>
            <!--<v-col cols="auto"
              class="header inactive"
              @click="toRoute ('/login/demo')"
            >
              {{ $t('label.demo') }}
            </v-col>-->
          </v-row>
        </div>
        <v-form
          ref="form"
          v-model="valid"
          class="auth-form"
          @submit.prevent="login()"
        >
          <!-- <v-text-field
            v-model="form.phone"
            _v-mask="phoneMask"
            v-mask="'+#############'"
            placeholder="Введите телефон"
            class="auth-text-field"
            outlined
            required
            :rules="phoneRules"
            @keyup.enter="submit()"
            @keydown="onKeydown"
            @input="(v) => { if (!v) phoneMask= '+7 (###) ###-##-##' }"
          >
            <template slot="prepend-inner">
              <v-img
                src="@/assets/svg/flag_russia.svg"
              />
            </template>
          </v-text-field> -->

          <vue-tel-input-vuetify
            :phone.sync="form.phone"
            :placeholder="$t('placeholder.phone')"
            required
          />

          <div
            class="auth-form-action"
            style="margin-top: 34px;"
          >
            <div
              style="display: inline-grid; margin-right: 5px;"
            >
              <v-btn
                color="primary"
                style="width: 100%;"
                :loading="loading"
                :disabled="!valid"
                @click="submit()"
              >
                <span
                  class="iconify"
                  style="margin-right: 8px;"
                  data-icon="ion:log-out-outline"
                  data-inline="false"
                />
                {{ $t('action.sign_in') }}
              </v-btn>
            </div>
            <div
              style="display: inline-grid; margin-left: 5px;"
            >
              <v-btn
                color="secondary"
                style="width: 100%;"
                :to="{path: '/login/email', query: $route.query}"
              >
                <span
                  class="iconify"
                  data-icon="ion:mail-outline"
                  data-inline="false"
                  style="margin-right: 8px;"
                />
                {{ $t('label.login_email') }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-col>
    </v-row>


    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="$config.app.RECAPTCHA_SITE_KEY"
      :load-recaptcha-script="true"
      @verify="login"
      @expired="onCaptchaExpired"
    />
  </div>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import { mapGetters } from 'vuex'
  import Routing from '@/mixins/routing'
  import VueRecaptcha from 'vue-recaptcha'
  import { validPhone } from '@/utils/validate'

  export default {
    components: {
      VueRecaptcha,
    },
    directives: { mask },
    mixins: [Routing],
    data () {
      return {
        form: {
          phone: null,
        },
        valid: true,
        visible1: false,
        phoneRules: [
          v => (!!v && validPhone(v)) || 'Введите телефон',
        ],
        loading: false,
        selectMerchant: false,
        phoneMask: '+7 (###) ###-##-##',
      }
    },
    computed: {
      ...mapGetters('auth/auth', [
        'merchants',
        'merchant',
        'device',
      ]),
    },
    mounted () {
      this.$store.dispatch('auth/auth/InitDevice')
    },
    methods: {
      onKeydown (e) {
        // console.log('onKeydown', e)
        if (!this.form.phone && e.key === '8') {
          this.phoneMask = '8 (###) ###-##-##'
        }
      },
      onCaptchaExpired () {
        this.$refs.recaptcha.reset()
      },
      toConfirm (phone) {
        console.log('toConfirm', phone)
        this.$router.push({
          path: '/login/phone/confirm',
          query: Object.assign({ phone }, this.$route.query),
        })
      },
      clearPhoneMask (p) {
        if (p) {
          p = String(p).match(/\d/g)
          if (p) p = p.join('')
        }
        return p
      },
      submit () {
        if (!this.$refs.form.validate()) return
        this.$refs.recaptcha.execute()
      },
      async login (recaptchaToken) {
        console.log('<login>')

        if (!this.$refs.form.validate() || !recaptchaToken) return

        const user = {
          phone: this.clearPhoneMask(this.form.phone),
          device_id: this.device.id,
          device_token: this.device.token,
          device_type: this.device.type,
          recaptcha_token: recaptchaToken,
        }
        console.log(user)
        try {
          this.loading = true
          await this.$store.dispatch('auth/phone/login', user)
          this.toConfirm(user.phone)
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>

<style lang="sass" scoped>
@import "~@/styles/auth"
</style>
